import React from 'react';
import {Link} from 'gatsby'

import VectorShape11 from '../WeServe/vector-shape11.png'

const WeServe = () => {
    return (
        <section className="industries-serve-area bg-200e36 pt-100 pb-10">
            <div className="container">
                <div className="section-title">
                    <h1>საინფორმაციო ცენტრი</h1>
                    <p>რესტორნები, მაღაზიები, ბიზნეს სესხები, დაზღვევა, მომსახურება</p>
                </div>

                <div className="row">
                <div className="col-lg-3 col-sm-6 col-md-6">
                        
                    <div className="single-industries-serve-box">
                               <div className="icon">
                                   <i className="flaticon-hospital"></i>
                               </div>
                               ბათუმი
                               <Link to="/ბათუმი" className="link-btn"></Link>
                           </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        
                        <div className="single-industries-serve-box">
                               <div className="icon">
                                   <i className="flaticon-hospital"></i>
                               </div>
                               ქუთაისი
                               <Link to="/ქუთაისი" className="link-btn"></Link>
                           </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        
                        <div className="single-industries-serve-box">
                               <div className="icon">
                                   <i className="flaticon-hospital"></i>
                               </div>
                               რუსთავი
                               <Link to="/რუსთავი" className="link-btn"></Link>
                           </div>
                    </div>
                       
                    {/* <div className="col-lg-3 col-sm-6 col-md-6">
                        
                     <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-hospital"></i>
                            </div>
                            ზუგდიდი
                            <Link to="/zugdidi" className="link-btn"></Link>
                        </div>
                    </div> */}
                    

                    {/* <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-hospital"></i>
                            </div>
                            სამედიცინო
                            <Link to="/სამედიცინო" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-tracking"></i>
                            </div>
                            ავტო
                            <Link to="/ავტო" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-tracking"></i>
                            </div>
                            ტრანსპორტი
                            <Link to="/ტრანსპორტირება" className="link-btn"></Link>
                        </div>
                    </div>


                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-investment"></i>
                            </div>
                            ავტონაწილები
                            <Link to="/ავტონაწილები" className="link-btn"></Link>
                        </div>
                    </div>

                    

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-order"></i>
                            </div>
                            სკოლები
                            <Link to="/სკოლები" className="link-btn"></Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-order"></i>
                            </div>
                            ავტოსერვისი
                            <Link to="/ავტოსერვისი" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-family-insurance"></i>
                            </div>
                            სანტექნიკა
                            <Link to="/სანტექნიკა" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-bitcoin"></i>
                            </div>
                            ფინანსები
                            <Link to="/ფინანსები" className="link-btn"></Link>
                        </div>

                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-order"></i>
                            </div>
                            ორგანიზაციები
                            <Link to="/ორგანიზაციები" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-family-insurance"></i>
                            </div>
                            მომსახურება
                            <Link to="/მომსახურება" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-bitcoin"></i>
                            </div>
                            სასწავლებლები
                            <Link to="/სასწავლებლები" className="link-btn"></Link>
                        </div>

                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-order"></i>
                            </div>
                            დასვენება
                            <Link to="/დასვენება" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-order"></i>
                            </div>
                            ტურები
                            <Link to="/ტურები" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-order"></i>
                            </div>
                            გართობა
                            <Link to="/გართობა" className="link-btn"></Link>
                        </div>
                    </div> */}



                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <Link to="/raionebi" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    ყველა ქალაქი<span></span>
                        </Link>
                    </div>



                </div>

            </div>
                <div className="vector-shape11 pt-100" >
                        <img src={VectorShape11} alt="Vector Shape" />
                </div>

        </section>
        
    )
}

export default WeServe;